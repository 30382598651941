<template>
  <section>
    <h3>
      {{ info.lastName}} {{info.firstName}} {{info.patronymic}}
    </h3>

    <div class="data-fields__container">
      <!-- <data-field key="dataField_1" v-if="info.dateOfBirth" :data-field="dataFields[0]"/> -->
      <data-field key="dataField_2" :data-field="dataFields[0]"/>

      <!-- <router-link to="/" class="edit__link">
        Изменить email
      </router-link> -->
     </div>
     <div class="data-fields__container">

      <data-field key="dataField_3" :data-field="dataFields[1]"/>

      <!-- <router-link to="/" class="edit__link">
        Изменить пароль
      </router-link> -->
    </div>
<!-- calendar.svg -->
    <h4 v-show="info.biography">
      Биография
    </h4>

    <p class="biography__text" v-html="biographyText">
    </p>
  </section>
</template>

<script>
import DataField from './DataField'

export default {
  name: 'PersonalAccountInfo',
  props: {
    info: {
      type: Object,
      default: () => ({
        id: null,
        firstName: '',
        lastName: '',
        patronymic: '',
        email: '',
        phone: '',
        photo: '',
        biography: ''
      })
    }
  },
  data () {
    return {
    }
  },
  components: {
    DataField
  },
  computed: {
    biographyText () {
      return this.info && this.info.biography ? this.info.biography.replace(/\n/g, '<br>') : ''
    },
    dataFields () {
      return [
        {
          label: 'Email',
          content: this.$props.info.email,
          style: {
            width: '19.375rem',
            marginRight: '2.625rem'
          }
        },
        {
          label: 'Телефон',
          content: this.$props.info.phone,
          style: {
            width: '19.375rem',
            marginRight: '2.625rem' // '9.06rem'
          }
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  section {
    width: 62.5rem;
    display: flex;
    flex-direction: column;

    > h3 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: #333333;
      margin-bottom: 2.06rem;
    }

    > .data-fields__container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 1.8rem;

      > .edit__link {
        font-size: .75rem;
        line-height: 1.125rem;
        color: #246CB7;
      }

      > * {
        margin-bottom: 1.875rem;
      }
    }

    > h4 {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.375rem;
      color: #333333;
    }

    .biography__text {
      font-size: .875rem;
      line-height: 1.375rem;
      color: #5E686C;
      margin-top: 1.063rem;
    }
  }
@media screen and (max-width: 768px){
  section{
    flex: auto;
    margin-left: 2rem;
    width: calc(100% - 19.375rem);
  }
}
@media screen and (max-width: 420px){
  section{
    width: auto;
    flex: auto;
    margin-left: 0;
    margin-top: 2rem;
  }
}
</style>
