<template>
  <form @submit.prevent="editUser" ref="editUser">
    <section class="info__container">
      <article>
          <img v-if="listField.photo" :src="`${previewBlob}`" alt="" class="photo">
          <img v-else src="/assets/icons/profile_photo.svg" alt="" class="photo">

          <div v-if="listField.photo" class="add_foto">
            <input type="file" id="fileInput" ref="fileInput" name="files" accept="image/*"  @input="myFunc"/>
            <label for="fileInput">Изменить изображение</label>
          </div>
          <div class="edit__link" v-if="listField.photo" @click="listField.photo = ''">Удалить фото</div>
          <div v-else class="add_foto">
            <input type="file" id="fileInput" ref="fileInput" name="files" accept="image/*"  @input="myFunc"/>
            <label for="fileInput">Загрузить <br> изображение профиля</label>
          </div>

      </article>
      <main>
        <div class="data-fields__container">
            <div class="contain__element">
                <p>Фамилия</p>
                <input type="text" name="nameField" id="dataField1" v-model="listField.lastName">
            </div>
            <div class="contain__element">
                <p>Имя</p>
                <input type="text" name="nameField" id="dataField2" v-model="listField.firstName">
            </div>
            <div class="contain__element">
                <p>Отчество</p>
                <input type="text" name="nameField" id="dataField3" v-model="listField.patronymic">
            </div>
            <div class="contain__element">
                <p>Телефон</p>
                <input type="tel" name="nameField" id="dataField4" v-model="listField.phone">
            </div>
        </div>
        <h4 v-show="!!info.biography || info.biography===null">
          Биография
        </h4>
        <span class="count__charts" v-show="!!info.biography || info.biography===null">{{number}}/5000</span>
        <textarea v-show="!!info.biography || info.biography===null" name="description" id="description" maxlength='5000'
          v-model="listField.biography" @keyup="clickTextarea(listField.biography)" class="biography__text">
        </textarea>
      </main>
    </section>

      <div class="contain__button active_border">
        <div class="contain__button__block">
            <p class="cancel" @click="$emit('editing', false)">Отмена</p>
            <p class="footnote">Поля помеченные <span>*</span> являются обязательными для заполнения</p>
        </div>
        <!-- <button type="" @click="submitForm" :class="{'next_color': false}">Отправить</button> -->
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </div>
  </form>
</template>

<script>
export default {
  name: 'EditingProfileInfo',
  props: {
    info: {
      type: Object,
      default: () => ({
        // id: null,
        // firstName: '',
        // lastName: '',
        // patronymic: '',
        // email: '',
        // phone: '',
        // photo: '',
        // biography: ''
      })
    }
  },
  data () {
    return {
      previewBlob: '',
      number: 5000,
      listField: {
        firstName: '',
        lastName: '',
        patronymic: '',
        biography: '',
        photo: '',
        phone: ''
      }
    }
  },
  computed: {
    biographyText () {
      return this.info && this.info.biography
    },
    selectData () {
      return this.info
    }
  },
  watch: {
    selectData () {
      const dataFromServer = this.info
      this.listField = {
        firstName: dataFromServer.firstName,
        lastName: dataFromServer.lastName,
        patronymic: dataFromServer.patronymic,
        biography: dataFromServer.biography,
        photo: dataFromServer.photo,
        phone: dataFromServer.phone
      }
      this.previewBlob = dataFromServer.photo
      this.$forceUpdate()
    },
    biographyText () {
      this.clickTextarea(this.info.biography)
    },
    '$store.getters.getMessageStatus' () {
      if (this.$store.getters.getMessageStatus === 200) {
        this.$emit('editing', false)
        this.$store.dispatch('setMessageStatus', '')
      }
      // console.log(this.$store.getters.getMessageStatus)
    }
  },
  methods: {
    clickTextarea (event) {
      this.number = 5000 - event.length
    },

    editUser () {
      const formData = new FormData()
      for (const key in this.listField) {
        var item = this.listField[key]
        if (item) {
          if (key === 'photo' && this.listField.photo === this.previewBlob) {
          } else {
            formData.append(key, item)
          }
        }
      }

      this.$store.dispatch('postPersonalData', formData)
    },
    myFunc (event) {
      const file = event.target.files[0]
      this.previewBlob = window.URL.createObjectURL(file)
      this.listField.photo = file
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";
  form{
    width: 100%;
  }
  .info__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  main {
    width: 62.5rem;
    display: flex;
    flex-direction: column;

    > .data-fields__container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -2.19rem 1.8rem 0;

      > .edit__link {
        font-size: .75rem;
        line-height: 1.125rem;
        color: #246CB7;
      }

      > * {
        margin-bottom: 1.875rem;
      }
    }

    > h4 {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.375rem;
      color: #333333;
    }

    textarea{
        width: 100%;
        height: 30.75rem;
        padding: 1rem 5rem 1rem 1.125rem;
        background: #EEF4FA;
        border: 0;
        border-radius: .19rem;
        font-size: .875rem;
        line-height: 1.38rem;
        color: #333333;
        // margin-top: 1.063rem;
    }
    .count__charts{
        position: relative;
        top: 3.5rem;
        left: calc(100% - 5rem);
        width: max-content;
        font-size: .75rem;
        line-height: 1.25rem;
        color: #9D9D9D;
    }

    textarea::-webkit-scrollbar {
        width: .125rem;}
    textarea::-webkit-scrollbar-track {
        -webkit-border-radius: .19rem;
        border-radius: .19rem;
        background: #D5D6D9;
    }
    textarea::-webkit-scrollbar-thumb {
        -webkit-border-radius: .19rem;
        border-radius: .19rem;
        background:#5A79A8;
    }
    textarea::-webkit-scrollbar-thumb:window-inactive { background:#5A79A8;}
  }
  .contain__element{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 19.38rem;
    margin-right: 2.125rem;
    p{
        font-size: .875rem;
        line-height: 1.375rem;
        color: #1F3245;
        margin-bottom: .56rem;
    }
    span{
        color: #EB494E;
    }
    input{
        width: 100%;
        height: 2.1875rem;
        padding: 0 .75rem;
        background: #EEF4FA;
        border-radius: .19rem;

        font-weight: 600;
        font-size: .875rem;
        line-height: 1.38rem;
        color: #1F3245;
    }
    input::placeholder{
        font-weight: normal !important;
        color: #5A79A8 !important;
        opacity: 0.55;
    }
    input:focus{
        background: #DDEDFE;
    }
}
article {
    width: 19.375rem;
    height: 25.624rem;

    background: white;
    border: .06rem solid #EBF3FF;
    box-sizing: border-box;
    box-shadow: 0 .93rem 1.562rem rgba(189, 205, 221, 0.35);
    border-radius: .5rem;
    padding: 1.875rem;

    > img {
      width: 15.625rem;
      height: 15.625rem;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      margin-bottom: 1.6875rem;
    }

    > .edit__link{
      font-size: .812rem;
      line-height: 1.375rem;
      text-align: center;
      color: $primary-color;
      margin-bottom: 1.062rem;
      cursor: pointer;
    }
    > .edit__link:last-child{
      margin-bottom: 0;
    }
  }

.add_foto{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow:hidden;

  label{
    display: block;
    transition: all .4s;

    font-size: .812rem;
    line-height: 1.375rem;
    text-align: center;
    color: $primary-color;
    margin-bottom: 1.062rem;
    cursor: pointer;
  }
  input{
    position: absolute;
    top: 0; left: 0;
    width: 1.45rem;
    height: 1.45rem;
    opacity: 0;
    cursor: pointer;
  }
}
.contain__button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2.5rem;
    padding-top: 2.5rem;
    border-top: .063rem solid #D5D6D9;
    &__block{
        display: flex;
        align-items: center;
        .cancel{
            width: 10rem;
            padding: .5rem 0 .44rem 0;
            margin-right: 1.375rem;
            text-align: center;
            font-size: .875rem;
            line-height: 1.25rem;
            color: #246CB7;
            border: .063rem solid #246CB7;
            box-sizing: border-box;
            filter: drop-shadow(0px .375rem 1.25rem rgba(36, 108, 183, 0.35));
            border-radius: .19rem;
            cursor: pointer;
        }
        .footnote{
            font-size: .75rem;
            line-height: 1.125rem;
            color: #333333;
            span{
                color: #EB494E;
            }
        }
    }
    button{
        width: 10rem;
        padding: .5rem 0 .44rem 0;
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.25rem;
        text-align: center;
        color: #ffffff;
        background: #246CB7;
        border-radius: .19rem;
        opacity: 0.5;
        cursor: pointer;
    }
}
@media screen and (max-width: 768px){
  main {
    padding-left: 2rem;
    width: calc(100% - 19.375rem);
  }
}
@media screen and (max-width: 420px){
  .info__container{
    display: flex;
    flex-direction: column;
  }
  article {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .photo {
      // width: 10rem;
    }
  }
  main {
    padding-left: 0;
    width: 100%;
    .data-fields__container{
      margin-right: 0;
      margin-top: 2rem;
      .contain__element{
        width: 100%;
        margin-right: 0;
      }
    }
  }
  .contain__button{
    // flex-direction: column;
    align-items: flex-start;

    .contain__button__block{
      flex-direction: column;
      align-items: flex-start;
      .cancel{
        margin-bottom: 1rem;
      }
    }
    .btn-blue{
      // margin-top: 1rem;
    }
  }
}
</style>
