import { render, staticRenderFns } from "./MyProjectBanner.vue?vue&type=template&id=dc948562&scoped=true&"
import script from "./MyProjectBanner.vue?vue&type=script&lang=js&"
export * from "./MyProjectBanner.vue?vue&type=script&lang=js&"
import style0 from "./MyProjectBanner.vue?vue&type=style&index=0&id=dc948562&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc948562",
  null
  
)

export default component.exports