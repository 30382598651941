<template>
  <article>
    <img v-if="card" :src="`${card}`" alt="" class="personal_photo">
    <img v-else src="/assets/icons/profile_photo.svg" alt="" class="personal_photo">

    <div class="edit__link" @click="$emit('editing', true)">
      <img src="/assets/icons/edit_pencil_blue.svg">
      Редактировать профиль
    </div>

      <router-link to="/" class="btn-blue">
        <p @click="removeClick"> Выйти из аккаунта</p>
      </router-link>

  </article>
</template>

<script>
export default {
  name: 'PersonalAccountCard',
  props: {
    card: {
      type: String,
      required: false
    }
  },
  methods: {
    removeClick () {
      this.$store.dispatch('logOut')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  article {
    width: 19.375rem;
    height: 25.624rem;

    background: white;
    border: .06rem solid #EBF3FF;
    box-sizing: border-box;
    box-shadow: 0 .93rem 1.562rem rgba(189, 205, 221, 0.35);
    border-radius: .5rem;
    padding: 1.875rem;

    > img {
      width: 15.625rem;
      height: 15.625rem;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      margin-bottom: 1.6875rem;
    }

    > .edit__link {
      font-size: .812rem;
      line-height: 1.375rem;
      text-align: center;
      color: $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.062rem;
      cursor: pointer;

      > img {
        margin-right: .75rem;
      }
    }

    > .btn-blue {
      display: block;
      text-align: center;
      width: 100%;
      box-shadow: 0 .375rem 1.25rem rgba(36, 108, 183, 0.35);
    }
  }
  @media screen and (max-width: 420px){
    article {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .personal_photo{
        width: calc(100% - 3rem);
      }
    }
  }
</style>
