<template>
  <article class="white-container">
    <div class="banner__title">
      <h1>
        МОЙ ПРОЕКТ — <span>МОЕЙ СТРАНЕ!</span>
      </h1>

      <p>
        Конкурс социально значимых проектов
      </p>

      <div class="buttons__wrapper">
        <router-link to="/project-submit-appeal" class="btn-red" v-if="!getPersonalData.project_appeal">
          Заполнить заявку
        </router-link>

        <router-link :to="`/project-submit-appeal/${getPersonalData.project_appeal.id}/edit`" class="btn-blue" v-else-if="getPersonalData.project_appeal.status_code === 'DRAFT'">
          Продолжить заполнение заявки
        </router-link>

        <router-link :to="`/project-map/${getPersonalData.project_appeal.id}`" class="btn-red" v-else>
          Посмотреть карту проекта
        </router-link>

        <router-link to="/my-project" class="btn-white">
          Положение конкурса
        </router-link>
      </div>
    </div>

    <img src="/assets/bg/bg_my_project.png">
  </article>
</template>

<script>
export default {
  name: 'MyProjectBanner',

  computed: {
    getPersonalData () {
      return this.$store.getters.getPersonalData
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars.scss";

  .white-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 3rem;
    height: 12.5rem;

    > .banner__title {
      display: flex;
      flex-direction: column;

      > h1 {
        font-weight: bold;
        font-size: 2.25rem;
        line-height: 2.75rem;
        letter-spacing: 0.5px;
        color: $mainFontColor;
        margin-bottom: .375rem;

        > span {
          color: $primary-color;
        }
      }

      > p {
        margin-bottom: 1.25rem;
        font-size: .875rem;
        line-height: 1.375rem;
        color: $tagFontColor;

      }

      > .buttons__wrapper {
        display: flex;
        flex-direction: row;

        > .btn-red, > .btn-blue {
          margin-right: 1.25rem;
        }
      }
    }

    > img {
      width: 42.125rem;
      height: 12.5rem;

      object-position: center 25%;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 768px){
    .white-container{
      padding: 1.1rem 1.5rem;
      img{
        display: none;
      }
    }
  }
  @media screen and (max-width: 420px){
    .white-container{
      height: 100%;
      .banner__title  > .buttons__wrapper{
        flex-direction: column;
        .btn-red{
          margin-right: 0;
          width: 100%
        }
        .btn-white{
          margin-top: 1rem;
          width: 100%
        }
      }
    }
  }
</style>
