<template>
  <div class="data__field" :style="dataField.style">
    <span class="label">
      {{ dataField.label }}
    </span>

    <span class="data">
      {{ getContent }}
      <img v-if="dataField.isPassword" class="password__button" src="/assets/icons/eye.svg" @click="togglePasswordOpen()"/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'DataField',
  props: {
    dataField: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isPasswordOpen: false
    }
  },
  methods: {
    togglePasswordOpen () {
      this.isPasswordOpen = !this.isPasswordOpen
    }
  },
  computed: {
    getContent () {
      return this.dataField.isPassword ? (
        this.isPasswordOpen ? this.dataField.content : '*******'
      ) : this.dataField.content
    }
  }
}
</script>

<style scoped lang="scss">
  .data__field {
    width: 19.375rem;
    border-bottom: .06rem dashed #D5D6D9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .5rem;

    > .label {
      font-size: .875rem;
      line-height: 1.375rem;
      color: #5E686C;
    }

    > .data {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.375rem;
      color: #333333;

      > .password__button {
        cursor: pointer;
      }
    }
  }
</style>
