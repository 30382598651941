<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>

    <div class="white-container">
      <h2>
        Личный кабинет
      </h2>

      <tricolor-line/>

      <div class="info__container">
        <personal-account-card :card="getPersonalData.photo" v-show="!showEdit" @editing="editProfile"/>
        <personal-account-info :info="getPersonalData" v-show="!showEdit"/>

        <editing-profile-info :info="getPersonalData" v-show="showEdit"  @editing="editProfile"/>
      </div>
    </div>

    <my-project-banner v-show="!showEdit"/>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import PersonalAccountCard from '@/components/personalAccount/PersonalAccountCard'
import PersonalAccountInfo from '@/components/personalAccount/PersonalAccountInfo'
import MyProjectBanner from '@/components/personalAccount/MyProjectBanner'
import EditingProfileInfo from '@/components/personalAccount/EditingProfileInfo'

export default {
  name: 'PersonalAccount',
  data () {
    return {
      showEdit: false,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Личный кабинет'
        }
      ]
    }
  },
  components: {
    MyProjectBanner,
    PersonalAccountInfo,
    PersonalAccountCard,
    TricolorLine,
    BreadCrumb,
    EditingProfileInfo
  },
  created () {
    if (localStorage.getItem('token')) {
      this.$store.dispatch('getPersonalData')
    }
  },
  computed: {
    getPersonalData () {
      return this.$store.getters.getPersonalData
    }
  },
  methods: {
    editProfile (event) {
      this.showEdit = event
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .white-container {
    margin-bottom: 3.125rem;
  }

  ::v-deep h2 {
    color: $mainFontColor;
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.75rem;
    width: 45.3rem;
    margin-bottom: 2rem;
  }

  .info__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.562rem;
  }
  @media screen and (max-width: 420px){
    h2{
      width: 100%;
    }
    .info__container {
      flex-direction: column;
    }
  }
</style>
